<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <QDynamicSubmenu
        base-url="/leads"
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :has-demo="hasDemo"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMyLeadsSubmenu",
    props: {
        hasDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "Leads",
            titlelink: [
                {
                    label: "view leads in OPT",
                    fun: this.OptSSO
                    //link: "https://sfgcrm1.com/index.aspx",
                },
            ],
        }
    },
    computed: {
        buttons() {
            var buttons = [{
                label: "My Leads",
                anchor: "#lead_datatable",
                icon: "fas fa-arrow-alt-circle-right",
            }];
            if (this.hasPermission("agent:RecruitingLeadOrders") || this.hasPermission("agent:Quility Digital LeadLeadOrders") || this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                buttons.push({
                    label: "Lead Orders",
                    to: "/leads/dx-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                    children: [{
                        label: "DX Lead Orders",
                        to: "/leads/dx-lead-orders"
                    }]
                })
                if (this.hasRole(["Staff", "SuperAdmin"])) {
                    buttons[1].children.push({
                        label: "Quility Digital Lead Orders",
                        to: "/leads/quility-digital-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
                if (this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                    buttons[1].children.push({
                        label: "Debt Free Lead Orders",
                        to: "/leads/dfl-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
                if (this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])) {
                    buttons[1].children.push({
                        label: "Approve Lead Orders",
                        to: "/leads/approve-digital-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    }),
                    buttons[1].children.push({
                        label: "Downline Lead Orders",
                        to: "/leads/baseshop-lead-order-report",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
            }
            buttons = [
                ...buttons,
                {
                    label: "Lead Fact Sheets",
                    to: "/page/leads-fact-sheets",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Digital Leads Training",
                    to: "/page/digital-leads",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ]
            return buttons;
        }
    },
    methods: {
        OptSSO() {
            QuilityAPI.getOptSsoUrl().then((json) => {
                if (json.Success) {
                    window.open(json.Message, '_blank');
                } else {
                    this.showError(json.Message)
                }
            }).catch((err) => {
                this.showError(err)
            })
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>

<template>
    <div>
        <q-lead-counts v-if="lead_counts" :counts="lead_counts" @updatefilters="updateFilters" />
        <v-card class="mt-2">
            <v-card-title class="q_leads_1 align-start" style="font-size: 1rem" :id="entity + '_datatable'">
                <v-col cols="12" sm="8">
                    <div class="white--text d-flex align-center">
                        <p class="q-display-3 mb-0" id="datatable">
                            {{ title }}
                        </p>
                        <v-btn dark icon @click="refresh">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                    </div>
                    <p class="pt-2 mb-0">
                        <v-btn class="pa-2" text small color="q_leads_4" @click="toggleFilters">
                            <v-icon small left>fas fa-filter</v-icon> Filter Leads
                        </v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details @keyup="maybeSearch" @click:append="refreshData" />
                </v-col>
            </v-card-title>
            <v-card-text v-show="$refs.filters && $refs.filters.show">
                <v-row class="d-flex justify-start">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
                </v-row>
            </v-card-text>
            <v-row class="mx-4 my-2">
                <v-col cols="12" sm="6">
                    <v-badge overlap color="transparent">
                        <template #badge>
                            <!-- <v-tooltip bottom>
                                TODO: Place here tooltip text
                                <template #activator="{ on, attrs }">
                                    <v-icon style="font-size: 14px !important;" v-bind="attrs" v-on="on" class="q_leads_4--text">far fa-question-circle</v-icon>
                                </template>
                                Here some helpful text about referrals
                            </v-tooltip> -->
                        </template>
                        <v-btn class="pa-2" text small color="q_leads_4" @click="create_referral_dialog = true">
                            <v-icon small left>fas fa-plus-square</v-icon> Create Referral
                        </v-btn>
                    </v-badge>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-end" v-if="total_items > 0 && !$vuetify.breakpoint.smAndDown">
                    <v-btn text small color="q_leads_4" @click="exportResults">
                        <v-icon small left>fas fa-file-export</v-icon> Export Results
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 pointer" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }" :loading="loading" :options.sync="options" :server-items-length="total_items" v-on:click:row="viewLead">
                <template v-slot:item.Email="{ item }">
                    <a :href="'mailto:' + item.Email" class="nowrap">{{ item.Email }}</a>
                </template>
                <template v-slot:item.LeadCode="{ item }">
                    <span class="nowrap">
                        <span>{{ item.LeadCode }}</span>
                    </span>
                </template>
                <template v-slot:item.FirstName="{ item }">
                    <span class="nowrap"> {{ item.FirstName }} {{ item.LastName }} </span>
                </template>
                <template v-slot:item.AssignDate="{ item }">
                    <div>
                        {{ formatDate(item.AssignDate) }}
                        <v-tooltip right v-if="item.AgentNotLicensed">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">fas fa-flag</v-icon>
                            </template>
                            <span>Non-Resident License Needed</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan="10">
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="dialog = true">Send to Power Dialer</v-btn>
                        </td>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="showSyncDialog">Sync Disposition</v-btn>
                        </td>
                    </tr>-->
                </template>
            </v-data-table>
            <v-row wrap>
                <v-col cols="12" class="text-center">
                    <v-btn @click="hardRefresh" v-if="hasRole(['SuperAdmin', 'Staff'])">
                        Hard Refresh of Leads (Last 90 Days) <v-icon small right>fas fa-redo-alt</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="view_lead_details" width="500px" max-width="90%" persistent>
            <v-card class="pa-6" min-height="" v-if="current_lead">
                <v-progress-linear color="q_leads_1" indeterminate v-if="saving"></v-progress-linear>
                <v-row wrap>
                    <v-col cols="12">
                        <h3>{{ current_lead.FirstName }} {{ current_lead.LastName }}</h3>
                        <p class="overline mb-4">
                            <span v-if="current_lead.Age">
                                Age: <strong>{{ current_lead.Age }}</strong> <br />
                            </span>
                            <span v-if="current_lead.Birthday">
                                DOB: <strong>{{ formatDate(current_lead.Birthday) }}</strong><br />
                            </span>
                            <span v-if="current_lead.LeadStatus">
                                Status: <strong>{{ current_lead.LeadStatus }}</strong><br />
                            </span>
                            <span v-if="current_lead.Sex">
                                Gender at birth: <strong>{{ current_lead.Sex }}</strong> <br />
                            </span>
                        </p>
                        <p class="overline mb-4">
                            <span v-if="current_lead.SourceofReferral">
                                Referral Source: <strong>{{ current_lead.SourceofReferral }}<br /></strong>
                            </span>
                            <span v-if="current_lead.LeadSource && hasRole(['Staff', 'Exec', 'SuperAdmin'])">
                                Source: <strong>{{ current_lead.LeadSource }}</strong> <br />
                            </span>
                            <span v-if="current_lead.LeadSubSource && hasRole(['Staff', 'Exec', 'SuperAdmin'])">
                                SubSource: <strong>{{ current_lead.LeadSubSource }}</strong> <br />
                            </span>
                            <span>
                                Exclusivity End Date: <strong>{{ current_lead.ExclusivityEndDate }}</strong> <br />
                            </span>
                            <span v-if="current_lead.LeadLevel">
                                Lead Level: <strong>{{ current_lead.LeadLevelAlias ?? current_lead.LeadLevel }}</strong> <br />
                            </span>
                            <span v-if="current_lead.LeadType">
                                Lead Type: <strong>{{ current_lead.LeadType }}</strong> <br />
                            </span>
                            <span v-if="current_lead.LeadCode">
                                Lead Code: <strong>{{ current_lead.LeadCode }}</strong> <br />
                            </span>
                            <span v-if="current_lead.AssignDate">
                                Assign Date: <strong>{{ formatDateDash(current_lead.AssignDate) }}</strong> <br />
                            </span>
                            <span v-if="current_lead.PurchaseAmount">
                                Cost: <strong>{{ formatLongCurrency(current_lead.PurchaseAmount) }}</strong> <br />
                            </span>
                        </p>
                        <v-btn
                            :disabled="!opt_btn_enable"
                            small
                            class="mr-2"
                            @click="pushLeadToOpt(current_lead.LeadCode)"
                        >Push Lead to Opt</v-btn>
                        <v-btn
                            :to="'/newbusiness/fif-form?lead_code=' + current_lead.LeadCode"
                            small
                            class="mr-2"
                        >Financial Information Form</v-btn>
                        <v-btn
                            v-if="canRequestCredit"
                            small
                            @click="showLeadCreditRequestForm"
                        >Request Credit</v-btn>
                        <v-row v-if="showLeadCreditRequest">
                            <v-col cols="12">
                                <h5>Lead Credit Request</h5>
                                <v-select :items="creditReasons" label="Reason" v-model="creditRequest.Reason"></v-select>
                                <q-lead-lookup v-if="creditRequest.Reason == 'Duplicate Lead'" v-model="DuplicateLeadAssignment" :return-object="true" label="Select the Duplicate Lead" :agent-code="agent.AgentCode" :all-leads="true" :filters="{'ExcludeLeadAssignmentID' : current_lead.LeadAssignmentID}"></q-lead-lookup>
                                <v-textarea label="Details" v-model="creditRequest.Notes"></v-textarea>
                                <v-btn block :disabled="!credit_request_valid" color="primary" @click="submitLeadCreditRequest">
                                    Submit Request
                                </v-btn>
                                <v-progress-linear indeterminate v-if="credit_saving" size="20"></v-progress-linear>
                            </v-col>
                        </v-row>


                        <h2>{{ current_lead.Contact }}</h2>
                        <v-list>
                            <!-- Lead's telephone -->
                            <v-list-item>
                                <v-list-item-action>
                                    <v-icon color="primary">fas fa-phone-alt</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <!-- <v-list-item-title><a :href="'tel:' + current_lead.Phone" v-if="current_lead.Phone"><span>{{ current_lead.Phone }}</span></a><span v-else>NA</span></v-list-item-title> -->
                                    <v-list-item-title>
                                        <span v-if="current_lead.Phone">
                                            <a :href="'tel:' + current_lead.Phone" class="mr-2">
                                                {{ current_lead.Phone }}
                                            </a>
                                            <span v-if="hasPermission('agent:ClickToDialAccess')">
                                                <v-btn v-if="!in_call" x-small color="primary" @click="call()">Dial</v-btn>
                                                <v-btn v-else x-small color="red" @click="hangUp()" dark>Hang up</v-btn>
                                            </span>
                                        </span>
                                        <span v-else>NA</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- Lead's email -->
                            <v-list-item v-if="current_lead.Email">
                                <v-list-item-action>
                                    <v-icon color="primary">fas fa-envelope</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title><a :href="'mailto:' + current_lead.Email">{{ current_lead.Email }}</a></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- Lead's PDF URL -->
                            <v-divider inset></v-divider>
                            <v-list-item v-if="current_lead.PDFURL" @click="openPDF">
                                <v-list-item-action>
                                    <v-icon color="primary">fas fa-file-pdf</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Original Lead</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <!-- Open Lead's Address on Google Maps -->
                            <v-list-item @click="openGoogleMaps">
                                <v-list-item-action>
                                    <v-icon color="primary">fas fa-map-marker-alt</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <p>
                                            {{ current_lead.Street }}<br />
                                            {{ current_lead.City }}, {{ current_lead.State }} {{ current_lead.Zip }}
                                        </p>
                                        <p>
                                            County: <span v-if="current_lead.County">{{ current_lead.County }}</span><span v-else>NA</span>
                                        </p>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </v-list>
                        <v-progress-linear color="q_leads_1" indeterminate v-if="status_items == null"></v-progress-linear>
                        <div v-else>
                            <span v-if="current_lead.LeadStatus != 'New Lead' && status_itemsHasIndexOf(current_lead.LeadStatus) == -1">
                                {{ current_lead.LeadStatus }}
                            </span>
                            <v-select v-else :disabled="user.Agent.AgentCode != agent.AgentCode" v-model="current_lead.LeadStatus" :items="status_items" item-text="LeadStatus" item-value="LeadStatus" label="Status" messages="Currently, some statuses can only be updated in Opt."></v-select>
                            <div v-if="getStatus(current_lead.LeadStatus) != null && getStatus(current_lead.LeadStatus).RequiresNote">
                                <v-textarea v-model="current_lead.Comments" label="Comments*" :rules="[form_rules.required]"></v-textarea>
                            </div>
                            <div v-else-if="getStatus(current_lead.LeadStatus) != null && getStatus(current_lead.LeadStatus).RequiresSchedule">
                                <q-date-picker label="Date*" v-model="current_lead.MetaData[current_lead.LeadStatus + 'ScheduleDate']" :rule="[form_rules.date, form_rules.required]"></q-date-picker>
                                <v-select label="Time*" prepend-icon="fas fa-clock" v-model="current_lead.MetaData[current_lead.LeadStatus + 'ScheduleTime']" :items="time_options" :rules="[form_rules.required]"></v-select>
                                <v-textarea v-model="current_lead.Comments" label="Comments*" :rules="[form_rules.required]"></v-textarea>
                            </div>
                            <div v-else>
                                <v-textarea v-model="current_lead.Comments" label="Comments" :rules="[form_rules.required]"></v-textarea>
                            </div>
                            <v-row class="d-flex px-5">
                                <v-btn depressed @click="
                                    view_lead_details = false;
                                    saving = false;
                                ">Cancel</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn depressed :disabled="saving || user.Agent.AgentCode != agent.AgentCode || !is_valid" color="primary" @click="updateLead">Save</v-btn>
                            </v-row>
                        </div>
                        <!-- Do we need the additional info? I think it's all included in this dialog
                        <v-row class="pt-8 pr-5">
                            <v-spacer></v-spacer>
                            <v-btn depressed right color="q_leads_1" dark :disabled="saving || (user.Agent.AgentCode != agent.AgentCode)" :to="'/leads/opt_lead/' + current_lead.LeadCode">More Details</v-btn>
                        </v-row>
                    -->
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <q-create-lead-referral :agent="agent" v-model="create_referral_dialog" :licensing="licensing" v-on:refresh="refresh"></q-create-lead-referral>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin";
import Lead from "@/store/Models/Lead";
import QuilityAPI from "@/store/API/QuilityAPI.js";
import { debounce } from "vue-debounce";
import QLeadCounts from "@/components/stats/QLeadCounts.vue";
import QDatePicker from "@/components/utils/QDatePicker.vue";
import { Device } from "@twilio/voice-sdk";
import rules from "@/components/utils/ValidationRules";
import StateOptions from "@/store/StateOptions";
import QDataTableHeader from "./base/QDataTableHeader.vue";
import QCreateLeadReferral from "@/components/utils/QCreateLeadReferral.vue";
import QLeadLookup from "@/components/utils/QLeadLookup.vue";
import moment from 'moment'

export default {
    name: "QLeadDataTable",
    mixins: [QDataTableMixin],
    props: ["agent"],
    data: function() {
        // data internal to component (not available outside)
        return {
            entity: "lead",
            view_lead_details: false,
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            leads: [],
            lead_counts: null,
            current_lead: null,
            options: { sortBy: ["AssignDate"], sortDesc: ["true"] },
            status_items: [],
            orig_lead_status: null,
            orig_comments: null,
            saving: false,
            is_exporting: false,
            twilioVoiceToken: null,
            in_call: false,
            device: null,
            create_referral_dialog: false,
            rules: rules, //validation rules imported.
            states: StateOptions, //for the state drop down list
            warning: null,
            error: null,
            validReferral: true,
            licensing: null,
            showLeadCreditRequest: false,
            creditRequest : {
                CreditLeadAssignmentID : null,
                Reason : null,
                DuplicateLeadAssignmentID : null,
                Notes: null
            },
            DuplicateLeadAssignment : null,
            credit_saving : false,
            opt_btn_enable : true
        };
    },
    mounted: function() {
        this.loadStatuses();
        this.getTwilioToken();
        this.loadlicensing();
    },
    computed: {
        filter_keys: function() {
            return Lead.state().filter_keys;
        },
        all_data: function() {
            return this.leads;
        },
        is_valid: function() {
            //check not requirements
            if (this.getStatus(this.current_lead.LeadStatus) != null && this.getStatus(this.current_lead.LeadStatus).RequiresNote) {
                return typeof this.current_lead.Comments != "undefined" && this.current_lead.Comments != null && this.current_lead.Comments != "";
            }
            //check date time requirements
            if (this.getStatus(this.current_lead.LeadStatus) != null && this.getStatus(this.current_lead.LeadStatus).RequiresSchedule) {
                return this.current_lead.Comments != null && this.current_lead.Comments != "" && this.current_lead.MetaData[this.current_lead.LeadStatus + "ScheduleTime"] != null && this.current_lead.MetaData[this.current_lead.LeadStatus + "ScheduleTime"] != "" && this.current_lead.MetaData[this.current_lead.LeadStatus + "ScheduleDate"] != null && this.current_lead.MetaData[this.current_lead.LeadStatus + "ScheduleDate"] != "" && this.form_rules.date(this.current_lead.MetaData[this.current_lead.LeadStatus + "ScheduleDate"]) === true;
            }
            return true;
        },
        the_headers() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [{
                        text: "Assign Date",
                        value: "AssignDate",
                        align: "start",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Name",
                        value: "FirstName",
                        align: "start",
                        sortable: true,
                        filterable: true,
                        class: "header-class",
                    },
                ];
            }

            if (this.$vuetify.breakpoint.md) {
                return [{
                        text: "Assign Date",
                        value: "AssignDate",
                        align: "start",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Name",
                        value: "FirstName",
                        align: "start",
                        sortable: true,
                        filterable: true,
                        class: "header-class",
                    },
                    {
                        text: "Type",
                        value: "LeadType",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Status",
                        value: "LeadStatus",
                        sortable: true,
                        filterable: true,
                    },
                ];
            }

            const r = [{
                    text: "Assign Date",
                    value: "AssignDate",
                    align: "start",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Name",
                    value: "FirstName",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    class: "header-class",
                },
                {
                    text: "State",
                    value: "State",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Phone",
                    value: "Phone",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Email",
                    value: "Email",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Type",
                    value: "LeadType",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Status",
                    value: "LeadStatus",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Level",
                    value: "LeadLevelAlias",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Referral Source",
                    value: "SourceofReferral",
                    sortable: true,
                    filterable: true,
                },
            ];

            if (this.hasRole(["Staff", "Exec", "SuperAdmin"])) {
                r.push({
                    text: "Source",
                    value: "LeadSource",
                    align: "start",
                });
                r.push({
                    text: "SubSource",
                    value: "LeadSubSource",
                    align: "start",
                });
            }
            return r;
        },
        time_options: function() {
            var r = [];
            var ampm = "AM";
            for (var a = 0; a < 2; a++) {
                for (var i = 1; i < 12; i++) {
                    for (var m = 0; m < 2; m++) {
                        r.push((i == 0 ? "00" : i) + ":" + (m == 0 ? "00" : m * 30) + " " + ampm);
                    }
                }
                r.push("12:00 PM");
                r.push("12:30 PM");
                ampm = "PM";
            }
            return r;
        },
        creditReasonsDetails : function(){
            return [
                {
                    title: 'Duplicate Lead',
                    bannedLeadLevels: [],
                },
                {
                    title: 'Bad Number',
                    bannedLeadLevels: ['LH'],
                },
                {
                    title: 'Over 80',
                    bannedLeadLevels: [],
                },
                {
                    title: 'Language Problem',
                    bannedLeadLevels: [],
                },
                {
                    title: 'Other',
                    bannedLeadLevels: [],
                },
            ];
        },
        creditReasons : function(){
            return this.creditReasonsDetails
                .filter(({ bannedLeadLevels }) => !bannedLeadLevels.length || !bannedLeadLevels.includes(this.current_lead.LeadLevel))
                .map(({ title }) => title);
        },
        'credit_request_valid' : function(){
            if (this.creditRequest.Reason == null){
                return false;
            }
            if (this.creditRequest.Reason == "Duplicate Lead" && this.DuplicateLeadAssignment == null){
                return false;
            }
            if (this.creditRequest.Reason == "Other" && this.creditRequest.Notes == null){
                return false;
            }
            return !this.credit_saving;
        },
        canRequestCredit(){
            if (!this.current_lead) {
                return false;
            }
            if (!(this.current_lead.PurchaseAmount > 0)) {
                return false;
            }
            if (this.current_lead.LeadStatus == "Credit Requested" || this.current_lead.LeadStatus == "Credit Approved") {
                return false;
            }

            //only allow requests less than 14 days from allocation. 
            let allocation_date = moment(this.current_lead.LeadAssignment.AssignDate)
            if (this.current_lead.LeadAllocation){
                allocation_date = moment(this.current_lead.LeadAllocation.AllocationDate)
            }
            const expire_date = moment().subtract(14, 'days');
            if (allocation_date < expire_date) {
                return false;
            }

            //only allow refunds on certain lead levels. 
            const allowed_lead_levels = ['IA', 'DX', 'DL', 'CI', 'LH', 'A', 'DLHA', 'CIA', 'MIA', 'DEA']; //temporary removing until resolved. 
            const leadTypesException = ['Recruiting'];
            if (!allowed_lead_levels.includes(this.current_lead.LeadLevel) && !leadTypesException.includes(this.current_lead.LeadType)) {
                return false;
            }

            return true;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this;
            if (this.lead_counts === null) {
                opt.NeedLeadCounts = true;
            } else {
                opt.NeedLeadCounts = false;
            }
            if (this.is_exporting) {
                opt.itemsPerPage = "csv";
            }
            QuilityAPI.getLeads(this.agent.AgentCode, this.filters, opt)
                .then(function(results) {
                    if (g.is_exporting) {
                        var fileURL = window.URL.createObjectURL(new Blob([results]));
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", "leads.csv");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        if (typeof results.data != "undefined") {
                            g.leads = results.data;
                            if (results.counts) {
                                g.lead_counts = results.counts;
                            }
                        } else {
                            g.showError("Whoops! " + results.message);
                        }
                    }
                    g.is_exporting = false;
                    Lead.commit((state) => {
                        state.loading = false;
                    });
                })
                .catch(function(err) {
                    console.log(err);
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.");
                        return;
                    }
                    //g.showError(err.status)
                });
            this.firstPull = false;
        }, 200),
        updateLead: function() {
            var g = this;
            this.saving = true;
            QuilityAPI.updateLead(this.agent.AgentCode, this.current_lead)
                .then(function(response) {
                    g.saving = false;
                    if (!response.Successfull) {
                        g.showError(response.Message);
                    } else {
                        g.orig_lead_status = g.current_lead.LeadStatus;
                        g.orig_comments = g.current_lead.Comments;
                        g.showSuccess(response.Message);
                        g.view_lead_details = false;
                        g.logActivity("UpdateLead", { LeadCode: g.current_lead.LeadCode });
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    g.showError(err.msg);
                });
        },
        pushLeadToOpt: function(leadcode) {
            this.opt_btn_enable = false
            var g = this;
            this.saving = true;
            QuilityAPI.pushLeadToOpt(this.agent.AgentCode, leadcode)
                .then(function(response) {
                    console.log(response);
                    g.saving = false;
                    if (!response.Successfull) {
                        g.showError(response.message);
                    } else {
                        g.showSuccess("This lead has been successfully pushed to Opt! It can take 30 minutes to an hour for it to show up in Opt.");
                    }
                    g.opt_btn_enable = true;
                })
                .catch(function(err) {
                    console.log(err);
                    g.showError(err.msg);
                    g.opt_btn_enable = true;
                });
        },
        viewLead: function(lead) {
            this.orig_lead_status = lead.LeadStatus;
            this.orig_comments = lead.Comments;
            this.current_lead = lead;
            this.view_lead_details = true;
            this.showLeadCreditRequest = false;
        },
        loadStatuses: function() {
            var g = this;
            QuilityAPI.getStatusOptions()
                .then(function(response) {
                    g.status_items = response;
                })
                .catch(function(err) {});
        },
        openGoogleMaps: function() {
            var url = "https://maps.google.com/?q=" + this.current_lead.Street + ", " + this.current_lead.City + ", " + this.current_lead.State + ", " + this.current_lead.Zip;
            window.open(url, "_blank");
        },
        openPhone: function() {
            var url = "tel:" + this.current_lead.Phone;
            window.open(url);
        },
        openPDF: function() {
            window.open(this.current_lead.PDFURL);
        },
        refresh: function() {
            this.leads = [];
            this.lead_counts = null;
            Lead.commit((state) => {
                state.total = null;
                state.availableFilters = null;
            });
            this.refreshData();
        },
        hardRefresh : function(){
            this.leads = [];
            this.lead_counts = null;
            Lead.commit((state) => {
                state.total = null;
                state.availableFilters = null;
            });
            var extra = {
                needAvailableFilters: true,
                hardRefreshLeads : true
            }
            var opt = {
                ...this.options,
                search: this.search,
                ...extra
            }
            this.loadData(opt);
        },
        exportResults: function() {
            var opt = {
                ...this.options,
                search: this.search,
            };
            var endpoint = "/api/private/leads/agent/" + this.agent.AgentCode;
            var params = {
                ...opt,
                ...this.filters,
                itemsPerPage: "csv",
            };
            this.dispatchJob({
                method: "GET",
                path: endpoint,
                params: params,
                JobName: "Leads Export",
            });

            //this.is_exporting = true
            //this.refreshData();
        },
        status_itemsHasIndexOf: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].LeadStatus == s) {
                    return true;
                }
            }
            return false;
        },
        getStatus: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].LeadStatus == s) {
                    return this.status_items[i];
                }
            }
            return null;
        },
        updateFilters(filters) {
            Object.keys(this.filters).forEach((key) => {
                this.filters[key] = filters[key] || "-All-";
            });

            this.goToTop();
        },
        goToTop: function() {
            this.$vuetify.goTo("#lead_datatable", {
                duration: 500,
                easing: "easeOutCubic",
                offset: 300,
            });
        },
        getTwilioToken: function() {
            fetch("/api/public/twilio/token")
                .then((response) => response.text())
                .then((token) => {
                    this.twilioVoiceToken = token;
                });
        },
        call: async function() {
            //initiate call to this.current_lead.Phone

            this.in_call = true;
            this.device = new Device(this.twilioVoiceToken);
            this.device.on("error", (twilioError, call) => {
                console.log("An error has occurred: ", twilioError);
            });

            let call = await this.device.connect({
                params: {
                    To: this.current_lead.Phone,
                },
            });
            call.on("disconnect", (connection) => {
                this.in_call = false;
            });
        },
        hangUp: function() {
            this.device.disconnectAll();
            this.in_call = false;
        },
        loadlicensing: function() {
            var g = this;

            QuilityAPI.getAgentLicensing(this.agent, this.role).then(function(resp) {
                if (resp.data) {
                    g.$set(g, "licensing", resp.data.licenses);
                } else {
                    g.showError(resp.reason);
                }
            });
        },
        showLeadCreditRequestForm : function(){
            this.creditRequest = {
                CreditLeadAssignmentID : this.current_lead.LeadAssignmentID,
                Reason : null,
                DuplicateLeadAssignmentID : null,
                Notes: null
            }
            this.DuplicateLeadAssignment = null;
            this.showLeadCreditRequest = !this.showLeadCreditRequest;
        },
        submitLeadCreditRequest : function(){
            if(this.creditRequest.Reason == "Duplicate Lead"){
                this.creditRequest.DuplicateLeadAssignmentID = this.DuplicateLeadAssignment.LeadAssignmentID;
            }
            var g = this
            this.credit_saving = true;
            QuilityAPI.submitLeadCreditRequest(this.agent.AgentCode, this.creditRequest).then(function(response){
                g.credit_saving = false;
                //we should returnt he new lead stattus and update it. 
                if (!response.Success) {
                    g.showError(response.message);
                } else {
                    g.credit_saving = false;
                    g.showSuccess("Credit Requested!");
                    g.view_lead_details = false;
                    g.logActivity("LeadCreditRequest", { LeadCode: g.current_lead.LeadCode });
                }
            }).catch(function(err){
                console.log(err)
                g.showError(err.msg);
                g.credit_saving = false;
            })
        }
    },
    watch: {
        view_lead_details: function() {
            this.current_lead.LeadStatus = this.orig_lead_status;
            this.current_lead.Comments = this.orig_comments;
            this.logActivity("ViewLead", { LeadCode: this.current_lead.LeadCode });
        },
    },
    components: {
        QLeadCounts,
        QDatePicker,
        QDataTableHeader,
        QCreateLeadReferral,
        QLeadLookup
    },
};
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}
</style>
<template>
    <div>
        <v-card class="text-left">
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="bulk_jobs_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">
                            {{ title }}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row>
                <v-col cols="12">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true"></q-filters>
                </v-col>
            </v-row>
            <v-data-table :mobile-breakpoint="2" :headers="the_headers" :server-items-length="total_num" :items="all_data" item-key="LeadAllocationID" class="elevation-0 row-pointer" :footer-props="{itemsPerPageOptions:[50, 100,200,500]}" :loading="loading" :options.sync="options" :expanded.sync="expanded" show-expand>
                <!--adds view ticket icon -->
                <template v-slot:item.AllocationDate="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.AllocationDate) }}
                    </span>
                </template>
                <template v-slot:item.PurchaseAmount="{ item }">
                    <span class="nowrap">
                        {{ formatLongCurrency(item.PurchaseAmount) }}
                    </span>
                </template>
                <template v-slot:item.SBID="{ item }">
                    <span class="nowrap">
                        <v-icon v-if="item.SBID && item.SBID != ''">fas fa-check</v-icon>
                    </span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span class="nowrap">
                        <v-btn x-small @click="viewHistoryLog(item)">Log</v-btn>
                    </span>
                </template>
                <template v-slot:item.AgentName="{ item }">
                    <span class="nowrap">
                        <router-link :to="'/agents/' + item.AgentCode">{{item.AgentName}}</router-link>
                    </span>
                </template>
                <template v-slot:expanded-item=" { item, headers }">
                    <td :colspan="headers.length" class="pa-4">
                        Switchboard Contact ID: {{item.SBID}}<br>
                        Allocation Date/Time:  {{item.AllocationDate}}<br>
                        Lead Code : {{item.LeadCode}}<br>
                        Lead Status : {{item.LeadStatus}}<br>
                        Product Type : {{item.ProductType}}
                    </td>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan="12">
                            <v-btn @click="downloadData">Export</v-btn>
                            <v-progress-linear v-if="loading" class="mt-2" color="primary" indeterminate></v-progress-linear>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "../QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import moment from 'moment'
import QFilters from '@/components/utils/QFilters.vue'


export default {
    name: "QLeadAllocationDataTable",
    props: ['title', 'agentCode'],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {
                "LeadType": "-All-",
                "LeadLevel": "-All-",
                "Weeks" : null
            },
            options: {
                'sortBy': ['AllocationDate'],
                'sortDesc': [true],
                'itemsPerPage' : 50
            },
            the_data: [],
            search: null,
            data_loading: false,
            expanded: [],
            showFilters: false,
            rows: 100,
            total_num : 0,
            available_data_filters : null,
            selected : []
        }
    },
    mounted: function() {
        this.refreshData()
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            if (this.loading) {
                return []
            }
            return this.the_data;
        },
        filter_keys: function() {
            return ['LeadType', "LeadLevel", "Weeks"];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers () {
			const headers = [{
					text: 'Allocation Date',
					value: 'AllocationDate',
					class: "nowrap",
					align: "left",
				}, {
					text: 'Order Number',
					value: 'OrderNumber',
					class: "nowrap",
					sortable: false,
				}, {
					text: 'Lead Level',
					value: 'LeadLevel',
					sortable: false,
					align: "center"
				}, {
					text: 'Lead Type',
					value: 'LeadType',
					sortable: false,
					align: "center"
				}, {
					text: 'First Name',
					value: 'FirstName',
					sortable: false,
					align: "left"
				}, {
					text: 'Last Name',
					value: 'LastName',
					sortable: false,
					align: "left"
				}, {
                    text: "Source",
                    value: "LeadSource",
					sortable: false,
                    align: "start",
				}, {
                    text: "SubSource",
                    value: "LeadSubSource",
					sortable: false,
                    align: "start",
				}, {
					text: 'State',
					value: 'State',
					sortable: false,
					align: "left"
				}, {
					text: 'Cost',
					value: 'PurchaseAmount',
					sortable: false,
					align: "left"
				},
			]
			if (!this.agentCode) {
				headers.splice(2, 0, {
					text: 'Agent',
					value: 'AgentName',
					class: "nowrap",
					sortable: false,
				})
			}
            return headers
        },
        available_filters: function() {
            var weeks = this.getAvailableBusinessDates('weeks', false, true, '2022-07-01');
            var week_selection = [];
            weeks.forEach((w) => {
                week_selection.push({
                    text : w.label,
                    value : {
                        endDate : w.endDateFormat,
                        startDate : w.startDateFormat,
                        'disableMultiple' : true
                    }
                })
            })
            var lead_types = this.available_data_filters ? this.available_data_filters.LeadType : []
            lead_types.unshift("-All-")
            var lead_levels = this.available_data_filters ? this.available_data_filters.LeadLevel : []
            lead_levels.unshift("-All-")
            return {
                "LeadType": lead_types,
                "LeadLevel" : lead_levels,
                "Weeks" : week_selection
            };
        },
    },
    methods: {
        refresh: function() {
            this.filters = {
                "LeadType": "-All-",
                "LeadLevel": "-All-",
                "Weeks" : null
            }
            this.available_data_filters = null
            this.refreshData();
        },
        refreshData: function() {
            this.the_data = []
            var opt = {
                ...this.options,
                LeadType : this.filters.LeadType,
                LeadLevel : this.filters.LeadLevel,
                startDate : this.filters.Weeks ? this.filters.Weeks.startDate : null,
                endDate : this.filters.Weeks ? this.filters.Weeks.endDate : null,
                AgentCode : this.agentCode ? this.agentCode : null,
                search : this.search
            }
            this.loadData(opt);
        },
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            if (this.available_data_filters == null){
                opt.needAvailableFilters = true
            }
            QuilityAPI.getAllocationReport({ ...opt }).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
                g.total_num = json.meta.total
                if (typeof json.meta.filters != 'undefined'){
                    g.available_data_filters = json.meta.filters
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        toggleFilters: function() {
            var g = this
            this.$nextTick(function() {
                g.$refs.filters.show = !g.$refs.filters.show
            })
        },
        downloadData: debounce(function() {
            if (typeof this.filters.Weeks.startDate == 'undefined'){
                this.showError("We recommend setting a date limit or else the export could timeout.")
                return
            }
            var opt = {
                ...this.options,
                LeadType : this.filters.LeadType,
                startDate : this.filters.Weeks ? this.filters.Weeks.startDate : null,
                endDate : this.filters.Weeks ? this.filters.Weeks.endDate : null,
                AgentCode : this.agentCode ? this.agentCode : null,
                search : this.search,
                'itemsPerPage': "csv",
            }
            this.dispatchJob({
                'method': "GET",
                'path': '/api/private/leads/lead_allocations/report',
                'params': opt,
                'JobName': "Lead Allocation Export"
            })
        }, 200),
        maybeSearch: function(event) {
            if (event.keyCode === 13) {
                this.refreshData();
            }
        },
    },
    watch: {
        'selectedDates.startDate': function() {
            var g = this;
            this.$nextTick(g.loadData);
        },
        'options': {
            deep: true,
            handler(newV) {
                //don't do anything
                this.$nextTick(() => this.refreshData())
            }
        },
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
                this.refreshData()
            }
        },
    },
    components: { QFilters}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.row-pointer tbody tr:hover {
    cursor: pointer;
}

</style>

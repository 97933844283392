<template>
    <v-dialog v-model="valueSync" width="500">
        <!-- <v-card v-if="current_policy" class="pa-6 text-center" min-height="">
                <div>
                    <q-pending-policy-detail :policy="current_policy"></q-pending-policy-detail>
                </div>
            </v-card> -->
        <v-card>
            <v-form v-if="!loading" lazy-validation v-model="validReferral" ref="referralForm" :disabled="saving" id="referral_form">
                <v-card-title class="q_leads_1">
                    <h4 class="white--text mb-0">Referral Information</h4>
                </v-card-title>
                <v-container class="pa-6">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="First Name*" v-model="referral_lead.FirstName" :rules="[rules.required,rules.not_upper_case,rules.no_special_characters]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Last Name*" v-model="referral_lead.LastName" :rules="[rules.required,rules.not_upper_case,rules.no_special_characters]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <span class="v-label v-label--active theme--light" style="font-size: 12px">Birth date*</span>
                            <div class="q-birth-date-input">
                                <v-text-field
                                    placeholder="MM" v-model="BirthDateMonth" :rules="[rules.required, rules.number]"
                                    style="max-width: 32px"
                                />
                                <v-text-field
                                    placeholder="DD" v-model="BirthDateDay" :rules="[rules.required, rules.number]"
                                    style="max-width: 32px"
                                />
                                <v-text-field
                                    placeholder="YYYY" v-model="BirthDateYear" :rules="[rules.required, rules.number]"
                                    style="max-width: 64px"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select label="Gender" :items="['Male','Female']" v-model="referral_lead.Sex"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Street Address" v-model="referral_lead.Street"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="City" v-model="referral_lead.City"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="County" v-model="referral_lead.County"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select label="State*" :items="states" v-model="referral_lead.State" :rules="[rules.required]"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Zip" v-model="referral_lead.Zip" :rules="[rules.zip]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field label="Phone Number*" v-model="referral_lead.Phone" :rules="[rules.required, rules.phone]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="Email*" v-model="referral_lead.Email" :rules="[rules.required, rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Home Phone" v-model="referral_lead.HomePhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="Cell Phone" v-model="referral_lead.CellPhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Work Phone" v-model="referral_lead.WorkPhone" :rules="[rules.phone]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-select label="Lead Type*" :items="['Mortgage Protection', 'Final Expense', 'Mortgage Acceleration', 'Mortgage Acceleration', 'Disability', 'Life', 'Life-New Parent', 'Spanish-MP', 'Retirement Solutions', 'Critical Illness', 'Debt Free Life']" v-model="referral_lead.LeadType" :rules="[rules.required]"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field label="Loan Amount" v-model="referral_lead.LoanAmount"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select label="Source of Referral*" :items="['Agent Referral', 'Client Referral', 'External' ]" v-model="referral_lead.SourceofReferral" :rules="[rules.required]"></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-alert type="error" v-if="error">
                            {{ error }}
                        </v-alert>
                        <v-alert type="warning" v-if="warning">
                            {{ warning }}
                        </v-alert>
                        <v-progress-linear right size="20" v-if="saving" indeterminate></v-progress-linear>
                        <div class="d-flex justify-space-between mt-2" style="width: 100%">
                            <v-btn color="transparent" depressed @click="$emit('input', false)">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" depressed @click="createReferral()" :disabled="saving">
                                Save
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import rules from '@/components/utils/ValidationRules'
import StateOptions from '@/store/StateOptions'
import QDatePicker from '@/components/utils/QDatePicker.vue'
export default {
    name: "QCreateLeadReferral",
    props: ['value', 'licensing', 'agent'],
    data() {
        return {
            referral_lead: {
                LeadType: null,
                LeadLevel: 'R',
                FirstName: null,
                LastName: null,
                Street: null,
                State: null,
                City: null,
                County: null,
                Zip: null,
                Phone: null,
                Sex: null,
                BirthDate: null,
                Age: null,
                HomePhone: null,
                CellPhone: null,
                WorkPhone: null,
                Email: null,
                LeadStatus: 'New',
                LoanAmount: null,
                LeadSource: 'Referral',
                LeadSubSource: this.agent.AgentName,
                DataSource: 'HQ',
                SourceofReferral: null,
                DataSourceID: this.agent.AgentID,
                LeadOriginUrl: window.location.href,
                AgentNotLicensedInd: null,
            },
            rules: rules,
            saving: false,
            warning: null,
            error: null,
            states: StateOptions,
            validReferral: true,
            loading: false
        };
    },
    computed: {
        valueSync: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        BirthDateMonth: {
            get() {
                return this.referral_lead.BirthDate ? this.referral_lead.BirthDate.split('-')[0] : '';
            },
            set(value) {
                this.referral_lead.BirthDate = value + '-' + this.BirthDateDay + '-' + this.BirthDateYear;
            }
        },
        BirthDateDay: {
            get() {
                return this.referral_lead.BirthDate ? this.referral_lead.BirthDate.split('-')[1] : '';
            },
            set(value) {
                this.referral_lead.BirthDate = [this.BirthDateMonth, value, this.BirthDateYear].join('-');
            }            
        },
        BirthDateYear: {
            get() {
                return this.referral_lead.BirthDate ? this.referral_lead.BirthDate.split('-')[2] : '';
            },
            set(value) {
                this.referral_lead.BirthDate = [this.BirthDateMonth, this.BirthDateDay, value].join('-');
            }
        },
    },
    methods: {
        createReferral: function() {
            var g = this
            this.saving = true
            let isLicensed = false
            //check the form to see if it is validated.
            if (!this.$refs['referralForm'].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }

            for (const index in this.licensing) {
                const data = this.licensing[index]
                isLicensed = data.state == this.referral_lead.state

                if (isLicensed) {
                    break
                }
            }

            this.referral_lead.AgentNotLicensedInd = !isLicensed

            QuilityAPI.createReferral(this.agent.AgentCode, this.referral_lead)
                .then(function(response) {
                    if (response.error) {
                        g.error = response.message
                    } else {
                        // reset create referral form
                        g.referral_lead = {
                            LeadType: null,
                            LeadLevel: 'R',
                            FirstName: null,
                            LastName: null,
                            Street: null,
                            State: null,
                            City: null,
                            County: null,
                            Zip: null,
                            Phone: null,
                            Sex: null,
                            BirthDate: null,
                            Age: null,
                            HomePhone: null,
                            CellPhone: null,
                            WorkPhone: null,
                            Email: null,
                            LeadStatus: 'New',
                            LoanAmount: null,
                            LeadSource: 'Referral',
                            LeadSubSource: g.agent.AgentName,
                            DataSource: 'HQ',
                            SourceofReferral: null,
                            DataSourceID: g.agent.AgentID,
                            LeadOriginUrl: window.location.href,
                            AgentNotLicensedInd: null,
                        }

                        // refresh leads datatable
                        g.$emit('refresh', response.data)
                        g.$emit('input', false)
                        g.saving = false
                    }

                })
                .catch(function(err) {
                    g.saving = false
                    g.message = 'Something went wrong'
                    console.log(err)
                })
        },
        goToTop: function() {
            this.$vuetify.goTo('#referral_form', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
    },
    components: {
        QDatePicker
    }
};
</script>
<style scoped lang="scss">
.scroll-overflow {
    max-height: 400px;
    overflow: scroll;
}

.q-birth-date-input {
    .v-input {
        display: inline-flex;
        max-width: 64px;
        margin-right: 8px;
        padding: 0;
        margin-top: 0;
    }
}
</style>